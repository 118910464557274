/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import e from "../../../../../core/ArrayPool.js";
import t from "../../../../../geometry/Extent.js";
import { create as i } from "../../../../../geometry/support/aaBoundingRect.js";
import s from "../../../../../rest/support/QuantizationParameters.js";
import o from "../../../tiling/TileKey.js";
class n {
  constructor(e, t) {
    this.key = new o(0, 0, 0, 0), this.bounds = i(), this.objectIds = new Set(), this.key.set(t);
    const s = e.getLODInfoAt(this.key);
    this.tileInfoView = e, this.tileInfoView.getTileBounds(this.bounds, this.key, !0), this.resolution = s.resolution, this.level = s.level, this.scale = s.scale, this.minScale = e.zoomToScale(s.level - 1), this.maxScale = e.zoomToScale(s.level + 1);
  }
  get lod() {
    return this.tileInfoView.getLODInfoAt(this.key);
  }
  get id() {
    return this.key.id;
  }
  get extent() {
    return t.fromBounds(this.bounds, this.tileInfoView.tileInfo.spatialReference);
  }
  get transform() {
    return {
      originPosition: "upperLeft",
      scale: [this.resolution, this.resolution],
      translate: [this.bounds[0], this.bounds[3]]
    };
  }
  createArcadeEvaluationOptions(e) {
    return {
      $view: {
        scale: this.scale,
        timeZone: e
      }
    };
  }
  createChildTiles() {
    const t = this.key.getChildKeys(),
      i = e.acquire();
    for (let e = 0; e < t.length; e++) i[e] = new n(this.tileInfoView, t[e]);
    return i;
  }
  getQuantizationParameters() {
    return s.fromJSON({
      mode: "view",
      originPosition: "upperLeft",
      tolerance: this.resolution,
      extent: {
        xmin: this.bounds[0],
        ymin: this.bounds[1],
        xmax: this.bounds[2],
        ymax: this.bounds[3],
        spatialReference: this.tileInfoView.tileInfo.spatialReference
      }
    });
  }
}
export { n as Tile };